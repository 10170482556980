import { EventEmitter } from "events";
// services
import Settings from "./settings";
import Gupport from "./gupport";
// types
import type { WsType, MessageKnown, Logs, Log as LogT, EventName, LogLevel, Data } from "../types/log";

type MessageData = MessageDataRequestLogs | MessageDataResponseLogs | MessageDataGupport;
interface MessageDataRequestLogs {
	type: "request_logs";
}
interface MessageDataResponseLogs {
	type: "response_logs";
	logs: Logs;
}
interface MessageDataGupport {
	type: "gupport";
	log: LogT;
}

const MAX_LOGS = 2000;

class Log extends EventEmitter {

	#listening = false; // eslint-disable-line no-unused-private-class-members
	#logs = new Map<WsType, Logs>();
	#logWindow: Window | null = null;

	constructor() {
		super();

		this.#listening = true;

		const type = "gupport";

		if (!this.#logs.has(type)) {
			this.#logs.set(type, []);
			this.emit("logTypeAdded");
		}

		Gupport.on("message", this.#onGupportMessage.bind(this, type));
		Gupport.on("error", this.#onGupportError.bind(this, type));
		Gupport.on("ready", this.#onGupportGeneral.bind(this, type, "ready", "debug"));
		Gupport.on("connecting", this.#onGupportGeneral.bind(this, type, "connecting", "debug"));
		Gupport.on("disconnected", this.#onGupportGeneral.bind(this, type, "disconnected", "debug"));
		Gupport.on("connected", this.#onGupportGeneral.bind(this, type, "connected", "debug"));
		Gupport.on("loginFailed", this.#onGupportGeneral.bind(this, type, "loginFailed", "error"));
	}

	#getMessageText(type: WsType, msg: MessageKnown): string {
		let message = msg.dir;
		if (msg.error) {
			message += ` - error - ${msg.error.message}`;
		}
		if (msg.payload === null) {
			return `${message} - unknown payload`;
		}
		if (msg.dir === "TX") {
			message += ` - action - ${msg.payload.action}`;
		} else {
			message += ` - info - ${msg.payload.info}`;
		}
		return message;
	}

	#onGupportMessage(type: WsType, msg: MessageKnown): void {
		const log: LogT = {
			id: globalThis.crypto.randomUUID(),
			level: (msg.error || msg.payload?.status === "error") ? "error" : "info",
			message: this.#getMessageText(type, msg),
			date: new Date(),
			dir: msg.dir,
			payload: msg.payload,
			error: msg.error
		};
		this.#updateLogs(type, log);
	}

	#onGupportError(type: WsType, error: Event): void {
		const log: LogT = {
			id: globalThis.crypto.randomUUID(),
			level: "error",
			message: "error",
			date: new Date(),
			error: error
		};
		this.#updateLogs(type, log);
	}

	#onGupportGeneral(type: WsType, eventName: EventName, logLevel: LogLevel, data: Data): void {
		const log: LogT = {
			id: globalThis.crypto.randomUUID(),
			level: logLevel,
			message: eventName,
			date: new Date(),
			data: data
		};
		this.#updateLogs(type, log);
	}

	#updateLogs(type: WsType, log: LogT): void {
		const logs = [log, ...this.#logs.get(type) as Logs].slice(0, MAX_LOGS);
		this.#logs.set(type, logs);

		this.emit("changed", type, log);
	}

	public getLogTypes(): Array<WsType> {
		return Array.from(this.#logs.keys());
	}

	public getLogsOfType(type: WsType): Logs {
		if (!this.#logs.has(type)) {
			console.warn("Log type is not available", type);
		}
		return this.#logs.get(type) ?? [];
	}

	public openLogWindow(): void {
		this.#logWindow = window.open(
			"logs.html",
			"LogWindow",
			"resizable,scrollbars,status"
		);
		this.on("gupport", this.#postToLogWindow);
		window.addEventListener("message", this.#receiveMessage.bind(this), false);
	}

	#postToLogWindow(log: LogT): void {
		if (this.#logWindow === null || this.#logWindow.closed) {
			this.off("gupport", this.#postToLogWindow);
			window.removeEventListener("message", this.#receiveMessage.bind(this), false);
		}
		const msg = {type: "gupport", log: log};
		this.#logWindow?.postMessage(msg, Settings.publicUrl);
	}

	#receiveMessage(event: MessageEvent<MessageData>): void {
		// Do we trust the sender of this message? (might be different from what we originally opened, for example).
		if (event.origin !== Settings.publicUrl) {
			return;
		}
		switch (event.data.type) {
			case "request_logs":
				event.source?.postMessage({response: "GET logs", logs: this.#logs}, Settings.publicUrl);
				break;
			case "response_logs":
				this.#logs.set("gupport", event.data.logs);
				this.emit("gupport");
				break;
			case "gupport":
				this.#updateLogs(event.data.type, event.data.log);
				break;
			default:
				// do nothing
		}
	}

	public listenForExternalLog(): void {
		window.addEventListener("message", this.#receiveMessage.bind(this), false);
		window.opener?.postMessage({request: "GET logs"}, Settings.publicUrl);
	}

}

export default (new Log());
