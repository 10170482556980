import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

type Props = {
	jsonString: string;
	onSend: (data: any) => void;
	onClose: () => void;
};

const jsonInvalid = (jsonString) => {
	try {
		const json = JSON.parse(jsonString);
		console.info(json);
		return false;
	} catch (error) {
		return true;
	}
};

const TerminalJsonEditor = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [json, setJson] = useState(props.jsonString);
	const [invalid, setInvalid] = useState(jsonInvalid(props.jsonString));

	useEffect(() => {
		setJson(props.jsonString);
	}, [props.jsonString]);

	const handleJsonChange = (event) => {
		const jsonString = event.target.value;
		setJson(jsonString);
		setInvalid(jsonInvalid(jsonString));
	};

	const handleSendClick = () => {
		const data = JSON.parse(json);
		props.onSend(data);
	};

	const handleCloseClick = () => {
		setInvalid(false);
		props.onClose();
	};

	return (
		<div style={{ width: "30%" }}>
			<TextField
				placeholder="{}"
				fullWidth={true}
				multiline={true}
				value={json}
				onChange={handleJsonChange}
				error={invalid}
				style={{ height: "calc(100% - 49px)" }}
				InputProps={{ style: { height: "100%", padding: 0, backgroundColor: (theme.palette.mode === "dark") ? grey[800] : grey[100] }, disableUnderline: true }}
				inputProps={{ style: { height: "100%", overflow: "hidden scroll" } }}
			/>
			<div style={{ display: "flex", justifyContent: "flex-end", margin: "6px" }}>
				<Button
					variant="contained"
					disabled={invalid}
					onClick={handleSendClick}
					style={{ marginRight: "8px" }}
				>
					{t("gateway.send")}
				</Button>
				<Button variant="contained" color="neutral" onClick={handleCloseClick}>{t("gateway.close")}</Button>
			</div>
		</div>
	);
};

TerminalJsonEditor.propTypes = {
	jsonString: PropTypes.string.isRequired,
	onSend: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default TerminalJsonEditor;
