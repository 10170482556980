import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
// services
import { icons } from "@local/theme";

class TraceLogDownload extends Component {

	constructor(props) {
		super(props);

		this.handleExportClick = this.handleExportClick.bind(this);
		this.handleExportAllClick = this.handleExportAllClick.bind(this);
	}

	handleExportAllClick() {
		const logs = this.props.traces.map((trace) => (
			`Session Id: ${trace.sessionId}\r\n${trace.logs.map((log) => ((typeof log.data === "string") ? log.data : JSON.stringify(log.data))).join("\r\n")}`
		)).join("\r\n\r\n");
		this.doDownload("Session-traces", logs);
	}

	handleExportClick() {
		const logs = this.props.logs.map((log) => ((typeof log.data === "string") ? log.data : JSON.stringify(log.data))).join("\r\n");
		this.doDownload(this.props.sessionId, logs);
	}

	doDownload(fileName, res) {
		const blob = new Blob([res], {type: "text/plain"});
		const url = URL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.href = url;
		tempLink.setAttribute("download", `${fileName}.txt`);
		tempLink.click();
	}

	render() {
		const { t } = this.props;
		return (
			<div>
				<Button
					variant="contained"
					size="small"
					startIcon={<icons.Download />}
					onClick={this.handleExportClick}
					style={{marginLeft: "12px"}}
				>
					{t("gateway.export")}
				</Button>
				<Button
					variant="contained"
					size="small"
					startIcon={<icons.Download />}
					onClick={this.handleExportAllClick}
					style={{marginLeft: "12px"}}
				>
					{t("gateway.exportAll")}
				</Button>
			</div>
		);
	}

}

TraceLogDownload.propTypes = {
	traces: PropTypes.arrayOf(
		PropTypes.shape({
			sessionId: PropTypes.string.isRequired,
			logs: PropTypes.arrayOf(
				PropTypes.shape({
					data: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
				})
			).isRequired
		})
	).isRequired,
	sessionId: PropTypes.string.isRequired,
	logs: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
		})
	).isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(TraceLogDownload);
