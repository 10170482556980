import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Ansi from "ansi-to-react";
import { Link } from "@mui/material";
import Na from "../na";

const TerminalEntry = (props) => {
	const { t } = useTranslation();

	const handeJsonTraceClick = (event) => {
		event.preventDefault();
		props.onJsonTraceClick(props.entry);
	};

	const getRequstId = () => {
		if (props.entry.data.requestId) {
			return `[..${props.entry.data.requestId.substring(props.entry.data.requestId.length - 4)}] `;
		} else if (props.entry.data.responseId) {
			return `[..${props.entry.data.responseId.substring(props.entry.data.responseId.length - 4)}] `;
		}
		return "";
	};

	const getAdditionalInfo = () => {
		if (props.entry.data.action) {
			switch (props.entry.data.action) {
				case "gatewayAction":
					return `${props.entry.data.action}[${props.entry.data.module}:${props.entry.data.function}]`;
				case "sendGeneralCmd":
					return `${props.entry.data.action}[Cl:${props.entry.data.clusterId}][Ep:${props.entry.data.endpoint}][CmdId:${props.entry.data.cmdId}][id:${props.entry.data.deviceId}]`;
				case "sendActionCmd":
					return `${props.entry.data.action}[Cl:${props.entry.data.clusterId}][Ep:${props.entry.data.endpoint}][CmdId:${props.entry.data.cmdId}][id:${props.entry.data.deviceId}]`;
				case "extendDelayedAction":
					return `${props.entry.data.action}[Cl:${props.entry.data.clusterId}][Ep:${props.entry.data.endpoint}][CmdId:${props.entry.data.cmdId}][id:${props.entry.data.deviceId}][delay:${props.entry.data.delayed}]`;
				default:
					return props.entry.data.action;
			}
		}
		if (props.entry.data.info) {
			if (props.entry.data.status === "error") {
				return `${props.entry.data.info}[Error:${props.entry.data.error ?? "unknown error"}]`;
			} else {
				switch (props.entry.data.info) {
					case "gatewayAction":
						return `${props.entry.data.info}[${props.entry.data.module}:${props.entry.data.function}]`;
					case "sendGeneralCmd":
						if (props.entry.data.sourceAction) {
							return `${props.entry.data.info}[Cl:${props.entry.data.clusterId}][Ep:${props.entry.data.sourceAction.endpoint}][CmdId:${props.entry.data.sourceAction.cmdId}][id:${props.entry.data.sourceAction.deviceId}]`;
						} else {
							return `${props.entry.data.info}[Cl:${props.entry.data.clusterId}]`;
						}
					case "sendActionCmd":
						if (props.entry.data.sourceAction) {
							return `${props.entry.data.info}[Cl:${props.entry.data.clusterId}][Ep:${props.entry.data.sourceAction.endpoint}][CmdId:${props.entry.data.sourceAction.cmdId}][id:${props.entry.data.sourceAction.deviceId}]`;
						} else {
							return `${props.entry.data.info}[Cl:${props.entry.data.clusterId}]`;
						}
					case "extendDelayedAction":
						if (props.entry.data.sourceAction) {
							return `${props.entry.data.info}[Cl:${props.entry.data.sourceAction.clusterId}][Ep:${props.entry.data.sourceAction.endpoint}][CmdId:${props.entry.data.sourceAction.cmdId}][id:${props.entry.data.sourceAction.deviceId}][delay:${props.entry.data.sourceAction.delayed}]`;
						} else {
							return props.entry.data.info;
						}
					case "attributeReport":
						return `${props.entry.data.info}[Cl:${props.entry.data.cluster_id}][Ep:${props.entry.data.endpoint}][Name:${props.entry.data.deviceName}][Type:${props.entry.data.defaultTextKey}]`;
					case "deviceReport":
						return `${props.entry.data.info}[Id:${props.entry.data.data.id}][Rocid:${props.entry.data.data.rocId}][DeviceType:${props.entry.data.data.attributes["Device Type"]}]`;
					case "gatewayInfo":
						return `${props.entry.data.info}[ZB:${props.entry.data.data.zigbee}][ZW:${props.entry.data.data.zwave}][WIFI:${props.entry.data.data.wireless}][Pair:${props.entry.data.data.pairing}][Group:${props.entry.data.data.group}]`;
					case "newDeviceJoining":
						if (props.entry.data.rocid) {
							return `${props.entry.data.info}[id:${props.entry.data.id}][pairState:${props.entry.data.pairState}][Rocid:${props.entry.data.rocid}]`;
						} else {
							return `${props.entry.data.info}[id:${props.entry.data.id}][pairState:${props.entry.data.pairState}]`;
						}
					default:
						return props.entry.data.info;
				}
			}
		}
		return "";
	};

	switch (props.entry.type) {
		case "cmd":
			return `${props.promptSymbol} ${props.entry.data}`;
		case "rtrace":
			return <Ansi>{props.entry.data}</Ansi>;
		case "jsonTrace":
			return (
				<Link
					href="#"
					onClick={handeJsonTraceClick}
					style={{color: (props.entry.dir === "rx") ? "#dd0000" : (props.entry.dir === "tx") ? "#00aa00" : "inherit"}}
				>
					{props.timestampEnabled ? `${props.entry.ts.substring(11, props.entry.ts.length)} ` : <Na />}
					{["tx", "rx"].includes(props.entry.dir)
						? `${(props.entry.dir === "tx") ? t("gateway.tx") : t("gateway.rx")}:${getRequstId()}${getAdditionalInfo()}`
						: props.entry.data
					}
				</Link>
			);
		default:
			console.warn("Unknown Entry type:", props.entry);
			return props.entry.data;
	}
};

TerminalEntry.propTypes = {
	entry: PropTypes.shape({
		type: PropTypes.oneOf(["cmd", "rtrace", "jsonTrace"]).isRequired,
		data: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
		ts: PropTypes.string,
		dir: PropTypes.oneOf(["tx", "rx"])
	}).isRequired,
	promptSymbol: PropTypes.string.isRequired,
	timestampEnabled: PropTypes.bool.isRequired,
	onJsonTraceClick: PropTypes.func.isRequired
};

export default TerminalEntry;
