import RocWs from "./roc-ws";
// services
import Settings from "./settings";
import Accounts from "./accounts";
import Providers from "./providers";

class CC extends RocWs {

	constructor(options) {
		super(options);

		this.ready = false;
		this.gatewayId = null;

		this.onAccountChanged = this.onAccountChanged.bind(this);
		this.onSettingsLoaded = this.onSettingsLoaded.bind(this);

		this.on("error", (error) => (console.warn(error))); // eslint-disable-line no-console
		Accounts.on("setDefault", this.onAccountChanged);
		Settings.on("clusterChanged", this.onAccountChanged);
		Settings.on("loaded", this.onSettingsLoaded);
		if (Settings.loaded) {
			this.onSettingsLoaded();
		}
	}

	onAccountChanged() {
		this.account = Accounts.getDefault(Settings.cluster.gupportWsUrl);
		let login;
		if (this.account) {
			login = Providers.gupportLogin(Settings.cluster.providers, this.account);
		}
		login ||= {action: "login"};
		login.tap = this.gatewayId;
		const url = Settings.cluster.ccWsUrl;
		this.setOptions({
			url: url,
			login: login,
			heartbeat: true,
			channel: Settings.channel
		});
		this.disconnect();
	}

	onSettingsLoaded() {
		this.ready = true;
		this.onAccountChanged();
		this.addActionMethods();
		this.emit("ready");
	}

	sendAction(actionName, payload, callback) {
		payload ||= {};
		payload.action = actionName;
		return this.send(payload, callback);
	}

	addActionMethods() {
		const schemas = [
			{
				data: {
					api: "cc",
					dir: "TX",
					label: "CC",
					description: "CC.",
					payload: {
						action: "cc"
					}
				},
				id: "cc-TX-cc"
			},
			{
				data: {
					api: "cc",
					dir: "TX",
					label: "getAutoComplete",
					description: "getAutoComplete.",
					payload: {
						action: "getAutoComplete"
					}
				},
				id: "cc-TX-getAutoComplete"
			}
		];
		schemas.forEach((schema) => {
			if (schema.data.api !== "cc" || schema.data.dir !== "TX") {
				return;
			}
			const action = schema.data.payload.action;
			this[action] = this.sendAction.bind(this, action);
		});
	}

	setGateway(gatewayId) {
		this.gatewayId = gatewayId;
		this.onAccountChanged();
	}

}

export default CC;
