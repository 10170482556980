import PropTypes from "prop-types";
import type { ReactNode } from "react";

type Props = {
	title: string;
	subTitle?: ReactNode;
	titleWidth: number;
	styleLeft: object;
	styleRight: object;
	children: ReactNode;
};

const RowEntry = (props: Props) => (
	<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px"}}>
		<div style={{width: `calc(${props.titleWidth}% - 24px)`, minWidth: "80px", paddingRight: "24px", fontWeight: 500, ...props.styleLeft}}>
			{(props.subTitle === null) ? props.title :
				<>
					<div style={{ fontWeight: 500 }}>{props.title}</div>
					<div className="row-entry-subtitle">{props.subTitle}</div>
				</>
			}
		</div>
		<div style={{width: `${100 - props.titleWidth}%`, ...props.styleRight}}>
			{props.children}
		</div>
	</div>
);

RowEntry.defaultProps = {
	subTitle: undefined,
	titleWidth: 30,
	styleLeft: {},
	styleRight: {}
};

RowEntry.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.node,
	titleWidth: PropTypes.number,
	styleLeft: PropTypes.object,
	styleRight: PropTypes.object,
	children: PropTypes.node.isRequired
};

export default RowEntry;
