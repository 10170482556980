import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
// cmp
import SaveLoadCCCTrace from "./save-load-ccc-trace";
import TraceLogDownload from "./trace-log-download";
import TerminalEntry from "./terminal-entry";
import TerminalInput from "./terminal-input";
import TerminalJsonEditor from "./terminal-json-editor";
// services
import { withTranslation } from "react-i18next";

class Terminal extends Component {

	constructor(props) {
		super(props);

		this.ENTRIES_MAX = 10000;

		this.state = {
			rTraceEnabled: true,
			jsonTraceEnabled: true,
			timestampEnabled: true,
			showJsonEditor: false,
			selectedJson: null,
			selectedJsonDir: null,
			scrollToBottom: true
		};

		this.refScrollElement = createRef();
		this.refInputElement = createRef();

		this.handleScroll = this.handleScroll.bind(this);
		this.handleTerminalClick = this.handleTerminalClick.bind(this);
		this.handleAutoComplete = this.handleAutoComplete.bind(this);
		this.handleRTracePrintChange = this.handleRTracePrintChange.bind(this);
		this.handleJsonTracePrintChange = this.handleJsonTracePrintChange.bind(this);
		this.handleTimestampChange = this.handleTimestampChange.bind(this);
		this.toggleJsonEditorVisibility = this.toggleJsonEditorVisibility.bind(this);
		this.handleJsonTraceSelect = this.handleJsonTraceSelect.bind(this);
		this.handleJsonEditorSend = this.handleJsonEditorSend.bind(this);
		this.handleJsonEditorClosed = this.handleJsonEditorClosed.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.sessionId && this.props.sessionId !== prevProps.sessionId && this.props.sessionId === "general") {
			this.setState({
				showJsonEditor: false,
				selectedJson: null,
				selectedJsonDir: null
			});
		}
		if (this.state.scrollToBottom) {
			this.refScrollElement.current.scrollTop = this.refScrollElement.current.scrollHeight - this.refScrollElement.current.offsetHeight;
		}
	}

	handleRTracePrintChange(event, isInputChecked) {
		this.setState({
			rTraceEnabled: isInputChecked
		});
	}

	handleJsonTracePrintChange(event, isInputChecked) {
		this.setState({
			jsonTraceEnabled: isInputChecked
		});
	}

	handleTimestampChange(event, isInputChecked) {
		this.setState({
			timestampEnabled: isInputChecked
		});
	}

	toggleJsonEditorVisibility() {
		this.setState((prevState) => ({
			showJsonEditor: !prevState.showJsonEditor
		}));
	}

	handleJsonTraceSelect(terminalEntry) {
		this.setState({
			showJsonEditor: true,
			selectedJson: JSON.stringify(terminalEntry.data, null, 2),
			selectedJsonDir: terminalEntry.dir
		});
	}

	handleJsonEditorSend(data) {
		const cmd = {
			action: "reTransmit",
			dir: this.state.selectedJsonDir,
			data: data
		};
		this.props.cc.send(cmd);
	}

	handleJsonEditorClosed() {
		this.setState({
			showJsonEditor: false,
			selectedJson: null,
			selectedJsonDir: null
		});
	}

	handleScroll(event) {
		if (Math.ceil(event.target.scrollTop) < event.target.scrollHeight - event.target.offsetHeight) {
			this.setState({
				scrollToBottom: false
			});
		} else {
			this.setState({
				scrollToBottom: true
			});
		}
	}

	handleTerminalClick() {
		if (this.refInputElement.current) {
			this.refInputElement.current.focus();
		}
	}

	handleAutoComplete(cmd, callback) {
		const data = {
			module: this.props.module,
			command: cmd
		};
		this.props.cc.getAutoComplete(data, callback);
	}

	getFilteredTerminalEntries() {
		const terminalEntries = this.props.terminalEntries.slice(-this.ENTRIES_MAX);
		if (this.state.rTraceEnabled && this.state.jsonTraceEnabled) {
			return terminalEntries;
		}
		return terminalEntries.filter((terminalEntry) => (
			(terminalEntry.type === "rtrace" && this.state.rTraceEnabled) || (terminalEntry.type === "jsonTrace" && this.state.jsonTraceEnabled)
		));
	}

	render() {
		const { t } = this.props;

		const basicStyles = {
			text: grey[400],
			backgroundColor: grey[900],
			fontFamily: "monospace",
			fontSize: "1em"
		};

		const defaultStyles = {
			terminal: {
				height: `calc(100vh - ${this.props.heightOffset + 42}px)`,
				overflow: "hidden scroll",
				color: basicStyles.text,
				backgroundColor: basicStyles.backgroundColor,
				fontFamily: basicStyles.fontFamily,
				fontSize: basicStyles.fontSize
			},
			list: {
				display: "flex",
				flexDirection: "column",
				margin: "8px 12px",
				whiteSpace: "pre-wrap",
				wordBreak: "break-all",
				cursor: "text"
			},
			listEntry: {
				padding: "2px 0"
			}
		};

		return (
			<>
				{(this.props.module === "ccc") &&
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0 12px" }}>
						<div>
							<FormControlLabel
								label={t("gateway.rtrace")}
								control={<Checkbox checked={this.state.rTraceEnabled} onChange={this.handleRTracePrintChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
							<FormControlLabel
								label={t("gateway.jsonTrace")}
								control={<Checkbox checked={this.state.jsonTraceEnabled} onChange={this.handleJsonTracePrintChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
							<FormControlLabel
								label={t("gateway.timestamp")}
								control={<Checkbox checked={this.state.timestampEnabled} onChange={this.handleTimestampChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
						</div>
						<div style={{ display: "flex" }}>
							{(this.props.sessionId !== "general") &&
								<Button
									variant="contained"
									size="small"
									onClick={this.toggleJsonEditorVisibility}
									style={{ marginRight: "50px" }}
								>
									{t("gateway.jsonEditor")}
								</Button>
							}
							<SaveLoadCCCTrace
								traces={this.props.traces}
								sessionId={this.props.sessionId}
								gatewayId={this.props.gatewayId}
								userId={this.props.userId}
								terminalEntries={this.getFilteredTerminalEntries()}
								onLoadFile={this.props.onLoadFile}
							/>
							{this.props.sessionId &&
								<TraceLogDownload
									traces={this.props.traces}
									sessionId={this.props.sessionId}
									logs={this.props.terminalEntries}
								/>
							}
						</div>
					</div>
				}
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div
						ref={this.refScrollElement}
						style={{ ...defaultStyles.terminal, ...this.props.style, width: this.state.showJsonEditor ? "70%" : "100%" }}
						onScroll={this.handleScroll}
						onClick={this.handleTerminalClick}
					>
						<pre style={{ ...defaultStyles.list, ...this.props.listStyle }}>
							<div onClick={(event) => (event.stopPropagation())}>
								{
									this.getFilteredTerminalEntries().map((terminalEntry, index) => (
										<div key={index} style={{ ...defaultStyles.listEntry, ...this.props.listEntryStyle }}>
											<TerminalEntry
												entry={terminalEntry}
												promptSymbol={this.props.promptSymbol}
												timestampEnabled={this.state.timestampEnabled}
												onJsonTraceClick={this.handleJsonTraceSelect}
											/>
										</div>
									))
								}
							</div>
							{(this.props.sessionId === null) &&
								<TerminalInput
									refInput={this.refInputElement}
									readOnly={this.props.readOnly}
									promptSymbol={this.props.promptSymbol}
									inputLineStyle={this.props.inputLineStyle}
									promptStyle={this.props.promptStyle}
									inputStyle={this.props.inputStyle}
									basicStyles={basicStyles}
									onAutoComplete={this.handleAutoComplete}
									onSendCmd={this.props.onSendCmd}
									onClear={this.props.onClear}
								/>
							}
						</pre>
					</div>
					{this.state.showJsonEditor &&
						<TerminalJsonEditor
							jsonString={this.state.selectedJson}
							onSend={this.handleJsonEditorSend}
							onClose={this.handleJsonEditorClosed}
						/>
					}
				</div>
			</>
		);
	}

}

Terminal.defaultProps = {
	cc: null,
	gatewayId: null,
	userId: null,
	heightOffset: 156,
	promptSymbol: ">",
	style: {},
	listStyle: {},
	listEntryStyle: {},
	inputLineStyle: {},
	promptStyle: {},
	inputStyle: {},
	traces: [],
	sessionId: null,
	onSendCmd: () => {},
	onClear: () => {}
};

Terminal.propTypes = {
	cc: PropTypes.object,
	module: PropTypes.oneOf(["ccc", "shell"]).isRequired,
	readOnly: PropTypes.bool.isRequired,
	gatewayId: PropTypes.string,
	userId: PropTypes.string,
	heightOffset: PropTypes.number,
	promptSymbol: PropTypes.string,
	style: PropTypes.object,
	listStyle: PropTypes.object,
	listEntryStyle: PropTypes.object,
	inputLineStyle: PropTypes.object,
	promptStyle: PropTypes.object,
	inputStyle: PropTypes.object,
	terminalEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
	sessionId: PropTypes.string,
	traces: PropTypes.arrayOf(PropTypes.object),
	onLoadFile: PropTypes.func.isRequired,
	onSendCmd: PropTypes.func,
	onClear: PropTypes.func,
	t: PropTypes.func.isRequired
};

export default withTranslation()(Terminal);
