import { useState } from "react";
import PropTypes from "prop-types";
import Svg from "../svg";

const TerminalInput = (props) => {
	const [cmd, setCmd] = useState("");
	const [cmdHistory, setCmdHistory] = useState([]);
	const [cmdHistorySelection, setCmdHistorySelection] = useState(0);

	const defaultStyles = {
		inputLine: {
			display: "flex",
			flexDirection: "row",
			alignItems: "baseline",
			paddingTop: "2px"
		},
		prompt: {
			paddingRight: "8px"
		},
		input: {
			padding: 0,
			width: "100%",
			border: "none",
			outline: "none",
			color: props.basicStyles.text,
			backgroundColor: props.basicStyles.backgroundColor,
			fontFamily: props.basicStyles.fontFamily,
			fontSize: props.basicStyles.fontSize
		},
		clearButtonContainer: {
			position: "relative",
			top: "3px",
			marginTop: "-3px",
			cursor: "pointer"
		}
	};

	const handleTab = (cmd) => {
		props.onAutoComplete(cmd, (error, msg) => {
			if (!error && msg.payload.status === "ok" && msg.payload.info === "getAutoComplete") {
				const cursorPosition = props.refInput.current.selectionStart;
				setCmd((prevCmd) => (prevCmd.substring(0, cursorPosition) + msg.payload.data + prevCmd.substring(cursorPosition)));
				props.refInput.current.selectionStart = cursorPosition + msg.payload.data.length;
				props.refInput.current.selectionEnd = cursorPosition + msg.payload.data.length;
			}
		});
	};

	const handleEnter = () => {
		props.onSendCmd(cmd);

		setCmdHistorySelection(cmdHistory.length + 1);
		setCmdHistory((prevCmdHistory) => ([...prevCmdHistory, cmd]));
		setCmd("");
	};

	const prevCommand = () => {
		if (cmdHistorySelection > 0) {
			setCmd(cmdHistory[cmdHistorySelection - 1]);
			setCmdHistorySelection((prevCmdHistorySelection) => (prevCmdHistorySelection - 1));
		}
	};

	const nextCommand = () => {
		if (cmdHistorySelection < cmdHistory.length - 1) {
			setCmd(cmdHistory[cmdHistorySelection + 1]);
			setCmdHistorySelection((prevCmdHistorySelection) => (prevCmdHistorySelection + 1));
		} else {
			setCmd("");
			setCmdHistorySelection(cmdHistory.length);
		}
	};

	const handleKeyDown = (event) => {
		switch (event.key) {
			case "Tab":
				event.preventDefault();
				handleTab(cmd.substring(0, event.target.selectionStart));
				break;
			case "Enter":
				event.preventDefault();
				handleEnter();
				break;
			case "ArrowUp":
				event.preventDefault();
				prevCommand();
				break;
			case "ArrowDown":
				event.preventDefault();
				nextCommand();
				break;
			default:
			// console.log(event, event.key);
		}
	};

	return (
		<div style={{ ...defaultStyles.inputLine, ...props.inputLineStyle }}>
			<span style={{ ...defaultStyles.prompt, ...props.promptStyle }}>{props.promptSymbol}</span>
			<input
				ref={props.refInput}
				type="text"
				autoFocus="autofocus"
				value={cmd}
				readOnly={props.readOnly}
				style={{ ...defaultStyles.input, ...props.inputStyle }}
				onChange={(event) => (setCmd(event.target.value))}
				onKeyDown={handleKeyDown}
			/>
			<div style={defaultStyles.clearButtonContainer} onClick={props.onClear}>
				<Svg src="actions/clear.svg" color={props.basicStyles.text} size={15} />
			</div>
		</div>
	);
};

TerminalInput.propTypes = {
	refInput: PropTypes.object.isRequired,
	readOnly: PropTypes.bool.isRequired,
	promptSymbol: PropTypes.string.isRequired,
	inputLineStyle: PropTypes.object.isRequired,
	promptStyle: PropTypes.object.isRequired,
	inputStyle: PropTypes.object.isRequired,
	basicStyles: PropTypes.shape({
		text: PropTypes.string.isRequired,
		backgroundColor: PropTypes.string.isRequired,
		fontFamily: PropTypes.string.isRequired,
		fontSize: PropTypes.string.isRequired
	}).isRequired,
	onAutoComplete: PropTypes.func.isRequired,
	onSendCmd: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired
};

export default TerminalInput;
