import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
// services
import Settings from "../../services/settings";
import { icons } from "@local/theme";

type Traces = Array<Trace>;
interface Trace {
	sessionId: string;
}

type Props = {
	userId?: string;
	gatewayId?: string;
	traces: Traces;
	terminalEntries: Array<object>;
	sessionId?: string;
	onLoadFile: (file: File) => void;
};

const SaveLoadCCCTrace = (props: Props) => {
	const { t } = useTranslation();
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const doDownload = (fileName: string, data: Array<object> | object) => {
		const jsonData = JSON.stringify(data, null, 2);
		const blob = new Blob([jsonData], {type: "application/json"});
		const url = URL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.href = url;
		tempLink.setAttribute("download", `${fileName}.json`);
		tempLink.click();
	};

	const storeTraceInfo = () => {
		const dateTime = new Date().toISOString().substring(0, 19).replace(/:/g, "");
		if (props.sessionId) {
			const trace = props.traces.find((trace) => (trace.sessionId === props.sessionId));
			doDownload(`${Settings.channel as string}_${props.userId ?? ""}_${props.sessionId}_${dateTime}`, trace);
		} else {
			doDownload(`${Settings.channel as string}_${props.gatewayId.replace(/:/g, "")}_${dateTime}`, props.terminalEntries);
		}
	};

	const handleUploadClick = () => {
		setShowUploadDialog(false);
		props.onLoadFile(selectedFile as File);
	};

	return (
		<>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.SaveIcon />}
				onClick={storeTraceInfo}
				style={{ marginLeft: "12px" }}
			>
				{t("gateway.saveSession")}
			</Button>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.Upload />}
				onClick={() => (setShowUploadDialog(true))}
				style={{ marginLeft: "12px" }}
			>
				{t("gateway.loadSession")}
			</Button>
			<Dialog
				open={showUploadDialog}
				fullWidth={true}
			>
				<DialogTitle>{t("gateway.uploadTitle")}</DialogTitle>
				<DialogContent>
					<input
						className="session-uploader"
						type="file"
						name="Load session"
						onChange={(event) => (setSelectedFile(event.target.files[0]))}
					/>
				</DialogContent>
				<DialogActions>
					<Button disabled={!selectedFile} onClick={handleUploadClick}>{t("gateway.upload")}</Button>
					<Button color="inherit" onClick={() => (setShowUploadDialog(false))}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

SaveLoadCCCTrace.defaultProps = {
	gatewayId: null,
	userId: null,
	sessionId: null
};

SaveLoadCCCTrace.propTypes = {
	gatewayId: PropTypes.string,
	userId: PropTypes.string,
	traces: PropTypes.arrayOf(
		PropTypes.shape({
			sessionId: PropTypes.string.isRequired,
			logs: PropTypes.arrayOf(
				PropTypes.shape({
					data: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
				})
			).isRequired
		})
	).isRequired,
	sessionId: PropTypes.string,
	terminalEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
	onLoadFile: PropTypes.func.isRequired
};

export default SaveLoadCCCTrace;

