import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const NA = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<span style={{display: "inline-block", color: theme.palette.text.secondary}}>
			{t("messages.na")}
		</span>
	);
};

export default NA;
